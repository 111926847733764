import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

import Button from "./Button"
import PinkChevronIcon from "./../assets/icons/pink-chevron.svg"
import InternalLink from "./InternalLink"
import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"
import { Fragment } from "react"
import TabbedContent from "./TabbedContent"
import Divider from "./Divider"
import Testimonial from "./Testimonial"
import FeaturedProjects from "./FeaturedProjects"
import CallToAction from "./CallToAction"
import Slider from "./Slider"
import TeamMembersSlider from "./TeamMembersSlider"

const StyledContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-bottom: 32px;
    }
  }

  h3 {
    color: #1c1c26;
    font-family: Poppins;
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    color: #1c1c26;
    font-family: Poppins;
    font-size: 20px;
    line-height: 32px;
  }

  h5 {
    color: #1c1c26;
    font-family: Poppins;
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
  }

  p {
    color: #66768e;
    font-family: Poppins;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 16px;
    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 32px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: #d42b69;
      &:hover {
        color: #d42b69;
      }
    }
    .gatsby-image-wrapper {
      margin: 0 auto;
      display: block;
    }
    strong {
      font-weight: 600;
    }
  }

  hr {
    margin: 0 0 32px 0;
    background-color: rgba(216, 216, 216, 0.5);
    height: 1px;
    border: none;
  }

  table {
    margin-bottom: 16px;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-bottom: 32px;
    }
    tr {
      color: #66768e;
      font-family: Poppins;
      font-size: 18px;
      line-height: 32px;
      @media screen and (min-width: ${breakpoints.md}px) {
        font-size: 20px;
        line-height: 36px;
      }
      td {
        padding: 8px;
        border: solid 1px #66768e;
      }
    }
  }

  ul,
  ol {
    color: #66768e;
    font-family: Poppins;
    font-size: 18px;
    line-height: 32px;
    margin-left: 32px;
    @media screen and (min-width: ${breakpoints.md}px) {
      font-size: 20px;
      line-height: 36px;
      margin-bottom: 32px;
    }

    li {
      margin-top: 16px;
      &:last-of-type {
        margin-bottom: 16px;
        @media screen and (min-width: ${breakpoints.md}px) {
          margin-bottom: 32px;
        }
      }
      a {
        color: #d42b69;
        &:hover {
          color: #d42b69;
        }
      }
    }
  }

  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }
`

const StyledLinkedParagraph = styled.div`
  position: relative;
  .desktop-link {
    display: none;
    @media screen and (min-width: ${breakpoints.md}px) {
      display: inline-flex;
      position: absolute;
      right: 0;
    }
  }
  .mobile-link {
    @media screen and (min-width: ${breakpoints.md}px) {
      display: none;
    }
  }
`

const StyledStats = styled.div`
  background-color: #eef1f4;
  .styled-stats-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${breakpoints.lg}px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .stat-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      @media screen and (min-width: ${breakpoints.lg}px) {
        max-width: 25%;
      }
      span {
        color: #1c1c26;
        font-family: Poppins;
        font-size: 52px;
        font-weight: 600;
        line-height: 64px;
        text-align: center;
      }
      p {
        color: #66768e;
        font-family: Roboto;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
      }
    }
  }
`

const StyledGallery = styled.div`
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 50% 25% 25%;
  height: 160px;
  @media screen and (min-width: 576px) {
    height: 385px;
  }
  @media screen and (min-width: 1000px) {
    height: 540px;
    grid-template-columns: 34% 22% 22% 22%;
  }

  .item-0 {
    grid-row: 1 / 3;
  }
  .item-2 {
    grid-column: 3 / 4;
    @media screen and (min-width: 1000px) {
      grid-column: 3 / 5;
    }
  }

  .item-3 {
    grid-column: 2 / 4;
  }

  .item-4 {
    display: none;
    @media screen and (min-width: 1000px) {
      display: block;
    }
  }
`

const StyledColumn = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
`

export default function FlexibleContent({ content: contentBoxes }) {
  if (!contentBoxes) return null

  return (
    <StyledContent>
      {contentBoxes.map(({ fieldGroupName, ...blockContent }, index) => {
        // calculate if this is the last in the loop so we don't add the spacing on the bottom
        const isLast = index === contentBoxes.length - 1

        if (fieldGroupName.includes("FullWidth")) {
          return (
            <div className="container" key={index}>
              <div className="row">
                <div className="col-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blockContent.content,
                    }}
                  />
                  {!isLast && <Spacer size={32} when={{ md: 48 }} />}
                </div>
              </div>
            </div>
          )
        } else if (fieldGroupName.includes("LinkedParagraph")) {
          return (
            <div className="container" key={index}>
              <div className="row">
                <div className="col-12">
                  <StyledLinkedParagraph>
                    <InternalLink
                      to={blockContent.link.link.url}
                      className="desktop-link"
                    >
                      {blockContent.link.label}
                    </InternalLink>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: blockContent.content,
                      }}
                    />
                    <Spacer size={24} when={{ md: 0 }} />
                    <Button
                      to={blockContent.link.link.url}
                      as={Link}
                      className="mobile-link"
                      variant="outline"
                      colour="primary"
                      icon={
                        <img
                          alt=""
                          src={PinkChevronIcon}
                          style={{
                            height: "16px",
                            marginLeft: "8px",
                          }}
                        />
                      }
                    >
                      {blockContent.link.label}
                    </Button>
                  </StyledLinkedParagraph>
                  {!isLast && <Spacer size={32} when={{ md: 48 }} />}
                </div>
              </div>
            </div>
          )
        } else if (fieldGroupName.includes("TabbedContent")) {
          return (
            <Fragment key={index}>
              <TabbedContent tabs={blockContent.tabs} />
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("Divider")) {
          return (
            <Fragment key={index}>
              <Divider />
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("CallToAction")) {
          return (
            <Fragment key={index}>
              <CallToAction content={blockContent} />
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("Testimonial")) {
          return (
            <Fragment key={index}>
              <Testimonial />
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("FeaturedProjects")) {
          return (
            <Fragment key={index}>
              <FeaturedProjects
                title={blockContent.title}
                showLinkToAllCaseStudies={blockContent.showLinkToAllCaseStudies}
                caseStudies={blockContent.caseStudies.map(
                  ({ caseStudy }) => caseStudy
                )}
              />
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("TeamMembersSlider")) {
          return (
            <Fragment key={index}>
              <TeamMembersSlider />
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("Slider")) {
          return (
            <Fragment key={index}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Slider
                      slidesToShow={1}
                      slides={blockContent.images.map((image, index) => {
                        if (
                          ["image/svg+xml", "image/gif"].includes(
                            image.mimeType
                          )
                        ) {
                          return (
                            <img
                              src={image.localFile.publicURL}
                              alt="Slider"
                              key={index}
                            />
                          )
                        } else {
                          return (
                            <GatsbyImage
                              image={
                                image.localFile.childImageSharp.gatsbyImageData
                              }
                              key={index}
                              objectFit="contain"
                              alt="Slider"
                              style={{
                                maxHeight: "100%",
                                height: "100%",
                              }}
                            />
                          )
                        }
                      })}
                    />
                  </div>
                </div>
              </div>
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("Stats")) {
          return (
            <Fragment key={index}>
              <StyledStats>
                <Spacer size={32} when={{ md: 56, lg: 80 }} />
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="styled-stats-wrapper">
                        {blockContent.stat.map(
                          ({ label, value }, statIndex) => (
                            <div className="stat-container" key={statIndex}>
                              <span>{value}</span>
                              <p>{label}</p>
                              {statIndex !== blockContent.stat.length - 1 && (
                                <Spacer size={32} when={{ lg: 0 }} />
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Spacer size={32} when={{ md: 56, lg: 80 }} />
              </StyledStats>
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("Gallery")) {
          return (
            <Fragment key={index}>
              <StyledGallery>
                {blockContent.images.map((image, index) => (
                  <div className={`item-${index}`} key={index}>
                    {image.mimeType === "image/svg+xml" ? (
                      <img src={image.localFile.publicURL} alt="gallery" />
                    ) : (
                      <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt="gallery"
                        style={{
                          maxHeight: "100%",
                          height: "100%",
                        }}
                      />
                    )}
                  </div>
                ))}
              </StyledGallery>
              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        } else if (fieldGroupName.includes("3Columns")) {
          return (
            <Fragment key={index}>
              <div className="container">
                <div className="row">
                  {blockContent.column.map(({ content }, index) => (
                    <div
                      className={`col-12 col-lg-${index === 1 ? 6 : 3}`}
                      key={index}
                    >
                      <StyledColumn
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {!isLast && <Spacer size={32} when={{ md: 48 }} />}
            </Fragment>
          )
        }
        return null
      })}
    </StyledContent>
  )
}
