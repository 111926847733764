import React, { useLayoutEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useSprings, animated } from "react-spring"
import styled from "styled-components"

import Slider from "./Slider"
import Spacer from "./Spacer"
import { breakpoints } from "../assets/globalStyles"

const SliderWrapper = styled.div`
  margin-right: -15px;
  margin-left: -15px;
  .slick-slide {
    padding: 0 16px;
  }
`

const StyledTeamMember = styled.div`
  position: relative;
  border: 1px solid #eef1f4;
  background-color: #ffffff;
  display: block;
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-bottom: 124px;
  }
  .image-background {
    background-color: #eef1f4;
    @media screen and (min-width: ${breakpoints.md}px) {
      max-height: 100%;
      height: 200px;
    }
    @media screen and (min-width: ${breakpoints.lg}px) {
      height: 250px;
    }
    @media screen and (min-width: ${breakpoints.xl}px) {
      height: 300px;
    }
    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: bottom;
    }
  }
  .member-details {
    background-color: #ffffff;
    padding: 24px;
    width: 100%;
    @media screen and (min-width: ${breakpoints.md}px) {
      position: absolute;
      overflow: hidden;
      bottom: 0;
    }
    h4 {
      color: #1c1c26;
      font-family: Poppins;
      font-size: 20px;
      line-height: 32px;
      margin: 0;
      @media screen and (min-width: ${breakpoints.md}px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
    p.role {
      color: #f03a85;
      font-family: Roboto;
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      @media screen and (min-width: ${breakpoints.md}px) {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
`

export default function TeamMembersSlider() {
  const {
    allWpTeamMember: { nodes: teamMembers },
  } = useStaticQuery(graphql`
    {
      allWpTeamMember {
        nodes {
          title
          teamMemberFields {
            description
            jobTitle
            image {
              mimeType
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 360
                    layout: CONSTRAINED
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const [windowWidth, setWindowWidth] = useState(
    typeof window === "undefined" ? 0 : window.innerWidth
  )

  useLayoutEffect(() => {
    if (typeof window === "undefined") return

    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const [hoveredIndex, setHoveredIndex] = useState()

  const hoverAnimations = useSprings(
    teamMembers.length,
    teamMembers.map((member, index) => ({
      maxHeight:
        hoveredIndex === index || windowWidth < breakpoints.md
          ? "424px"
          : "124px",
    }))
  )

  // combine aniamtions with team members
  const formattedTeamMembers = teamMembers.map((member, index) => ({
    ...member,
    animationProps: hoverAnimations[index],
  }))

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <SliderWrapper>
            <Slider
              slidesToShow={3}
              responsive={[
                {
                  breakpoint: breakpoints.lg,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: breakpoints.sm,
                  settings: {
                    slidesToShow: 1,
                    dots: true,
                  },
                },
              ]}
              slides={formattedTeamMembers.map(
                (
                  {
                    title,
                    teamMemberFields: { image, description, jobTitle },
                    animationProps,
                  },
                  index
                ) => (
                  <StyledTeamMember
                    key={index}
                    onMouseEnter={() => {
                      setHoveredIndex(index)
                    }}
                    onMouseLeave={() => {
                      setHoveredIndex()
                    }}
                  >
                    <div className="image-background">
                      {["image/svg+xml", "image/gif"].includes(
                        image.mimeType
                      ) ? (
                        <img
                          src={image.localFile.publicURL}
                          alt="Slider"
                          key={index}
                        />
                      ) : (
                        <GatsbyImage
                          image={
                            image.localFile.childImageSharp.gatsbyImageData
                          }
                          key={index}
                          alt="Slider"
                          objectFit="contain"
                          objectPosition="bottom"
                          style={{
                            maxHeight: "100%",
                            height: "100%",
                          }}
                          onMouseEnter={() => {
                            setHoveredIndex(index)
                          }}
                          onMouseLeave={() => {
                            setHoveredIndex()
                          }}
                        />
                      )}
                    </div>

                    <animated.div
                      className="member-details"
                      style={animationProps}
                    >
                      <h4>{title}</h4>
                      <Spacer size={8} />
                      <p className="role">{jobTitle}</p>
                      <Spacer size={24} />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<p>${description}</p>`,
                        }}
                      />
                    </animated.div>
                  </StyledTeamMember>
                )
              )}
            />
          </SliderWrapper>
        </div>
      </div>
    </div>
  )
}
